import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "newAnag",
    "anag",
    "model",
    "selectModelFields",
    "newAnagModal",
    "elementTotal",
    "elementSpettanza",
    "importo",
    "prezzo",
    "differenza",
    "spettanza",
    "ricavi",
    "ricaviPercentuale",
    "statoAvanzamento",
    "motivoLostContainer",
    "motivoLost",
  ];
  static values = {
    anagDetailsUrl: String,
  };

  connect() {
    this.anagModal = new Modal(this.newAnagModalTarget);
    this.showHideLostReason();
  }

  showCreateAnagFields() {
    this.anagModal.show();
  }
  hideCreateAnagFields() {
    this.anagModal.hide();
  }

  checkCompletion(event) {
    if (event.detail.formSubmission.result.success) {
      this.anagModal.hide();
      let event = new Event("change");
      setTimeout(() => this.anagTarget.dispatchEvent(event), 200);
    }
  }

  showHideLostReason(event) {
    if (this.hasStatoAvanzamentoTarget && this.hasMotivoLostContainerTarget) {
      if (this.statoAvanzamentoTarget.value == "lost") {
        this.motivoLostContainerTarget.classList.remove("d-none");
        this.motivoLostTarget.required = true;
      } else {
        this.motivoLostContainerTarget.classList.add("d-none");
        this.motivoLostTarget.required = false;
      }
    }
  }

  updateTotals() {
    // let prezzo = 0.0;
    // let importo = 0.0;
    // let spettanza = 0.0;
    // this.elementTotalTargets.forEach((element) => {
    //   let value = parseFloat(element.value);
    //   if (value) {
    //     importo += parseFloat(element.value);
    //   }
    // });
    // this.elementSpettanzaTargets.forEach((element) => {
    //   let value = parseFloat(element.value);
    //   if (value) {
    //     spettanza += parseFloat(element.value);
    //   }
    // });
    // if (importo > 0) {
    //   this.importoTarget.value = importo.toFixed(2);
    // } else {
    //   this.importoTarget.value = "";
    // }
    // if (spettanza > 0) {
    //   this.spettanzaTarget.value = spettanza;
    // } else {
    //   this.spettanzaTarget.value = "";
    // }
    // if (importo > 0 && spettanza > 0) {
    //   let ricavi = importo - spettanza;
    //   this.ricaviTarget.value = ricavi.toFixed(2);
    //   this.ricaviPercentualeTarget.value = ((ricavi * 100) / importo).toFixed(
    //     2
    //   );
    // }
  }

  async updateAnagFields(event) {
    let params = new URLSearchParams();
    params.append("id", this.anagTarget.selectedOptions[0].value);

    const response = await get(`${this.anagDetailsUrlValue}?${params}`, {
      responseKind: "json",
    });

    let json = await response.json;

    if (json) {
      // this.differenzaTarget.value = json.sconto_percentuale;
    } else {
      // this.differenzaTarget.value = "";
    }

    this.updateTotals();
  }
}
