import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";
import { Collapse } from "bootstrap";
const _ = require("lodash");

export default class extends Controller {
  static targets = [
    "addFiltersButton",
    "removeFiltersButton",
    "collapse",
    "removedFilters",
    "carClinicIntegration",
    "carClinicFilters",
  ];

  connect() {
    if (this.hasCollapseTarget) {
      this.collapse = new Collapse(this.collapseTarget, { toggle: false });
    }
    this.showHideCarClinicFilters();
  }

  showHideCarClinicFilters(event) {
    if (this.hasCarClinicIntegrationTarget) {
      let carClinicIntegration = this.carClinicIntegrationTarget;
      if (this.hasCarClinicFiltersTarget) {
        let carClinicFilters = this.carClinicFiltersTarget;
        if (carClinicIntegration.checked) {
          carClinicFilters.classList.remove("d-none");
        } else {
          let selects = carClinicFilters.getElementsByTagName("select");
          if (selects.length > 0) {
            Array.from(selects).forEach((item) => {
              item.value = "";
              if (item && item.tomselect) {
                item.tomselect.clear();
              }
            });
          }

          let inputs = carClinicFilters.getElementsByTagName("input");
          if (inputs.length > 0) {
            Array.from(inputs).forEach((item) => {
              item.value = "";
            });

            Array.from(inputs).forEach((item) => {
              if (item.type == "checkbox" || item.type == "radio") {
                item.value = "";
              }
            });
          }

          let datepickers =
            carClinicFilters.getElementsByClassName("datepicker");
          if (datepickers.length > 0) {
            Array.from(datepickers).forEach((item) => {
              item.value = "";
            });
          }

          carClinicFilters.classList.add("d-none");
        }
      }
    }
  }

  addFilters(event) {
    event.preventDefault();
    this.collapse.show();
    if (this.hasAddFiltersButtonTarget && this.hasRemoveFiltersButtonTarget) {
      this.addFiltersButtonTarget.classList.add("d-none");
      this.removeFiltersButtonTarget.classList.remove("d-none");
    }
  }

  removeFilters(event) {
    event.preventDefault();

    this.collapse.hide();
    if (this.hasAddFiltersButtonTarget && this.hasRemoveFiltersButtonTarget) {
      this.removeFiltersButtonTarget.classList.add("d-none");
      this.addFiltersButtonTarget.classList.remove("d-none");
    }

    let that = this;

    let selects = that.element.getElementsByTagName("select");
    if (selects.length > 0) {
      Array.from(selects).forEach((item) => {
        item.value = "";
        if (item && item.tomselect) {
          item.tomselect.clear();
        }
      });
    }

    let inputs = that.element.getElementsByTagName("input");
    if (inputs.length > 0) {
      Array.from(inputs).forEach((item) => {
        item.value = "";
      });

      Array.from(inputs).forEach((item) => {
        if (item.type == "checkbox" || item.type == "radio") {
          item.value = "";
        }
      });
    }

    let datepickers = that.element.getElementsByClassName("datepicker");
    if (datepickers.length > 0) {
      Array.from(datepickers).forEach((item) => {
        item.value = "";
      });
    }

    if (this.hasRemovedFiltersTarget) {
      this.removedFiltersTarget.value = "true";
    }

    let loadingMask = document.getElementById("loading-mask");
    if (loadingMask) {
      loadingMask.classList.remove("d-none");
    }
    this.element.closest("form").requestSubmit();
    let element = document.getElementById("items_table");
    if (this.hasRemovedFiltersTarget) {
      this.removedFiltersTarget.value = "false";
    }
    if (element && element.src) {
      element.addEventListener(
        "turbo:frame-load",
        navigator.history.push(new URL(element.src))
      );
    }
  }
}
