import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";
let debounce = require("lodash/debounce");
let throttle = require("lodash/throttle");

export default class extends Controller {
  static targets = [
    "checkbox",
    "actionsContainer",
    "operation",
    "value",
    "dataLavorazione",
    "statoLavorazione",
    "sede",
    "sconto",
    "anag",
    "humanResource",
    "numeroFattura",
    "dataFattura",
    "lostQuotationElementReason",
  ];

  initialize() {
    this.showHideActions = throttle(this.showHideActions, 1000).bind(this);
  }

  connect() {}

  showHideActions() {
    let showActions = false;
    this.checkboxTargets.forEach((element) => {
      let value = element.checked;
      if (value) {
        showActions = true;
        element.closest("tr").classList.add("table-warning");
      } else {
        element.closest("tr").classList.remove("table-warning");
      }
    });
    if (showActions) {
      this.actionsContainerTargets.forEach((element) => {
        element.classList.remove("d-none");
      });
    } else {
      this.actionsContainerTargets.forEach((element) => {
        element.classList.add("d-none");
      });
    }
  }

  closeActions(e) {
    e.preventDefault();
    e.stopPropagation();
    this.checkboxTargets.forEach((element) => {
      element.checked = false;
    });
    this.showHideActions();
  }

  selectCategory(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log(e.target.dataset.category);
    let elements = document.querySelectorAll(
      "[data-category='" + e.target.dataset.category + "']"
    );
    this.checkboxTargets.forEach((element) => {
      element.checked = false;
    });
    elements.forEach((element) => {
      element.checked = true;
    });
    this.showHideActions();
  }

  showLoading() {
    let loadingMask = document.getElementById("loading-mask");
    if (loadingMask) {
      loadingMask.classList.remove("d-none");
    }
  }

  autorizza(e) {
    e.preventDefault();
    this.operationTarget.value = "autorizzato";
    this.valueTarget.value = "true";
    this.showLoading();
    this.element.requestSubmit();
    // this.actionsContainerTarget.classList.add("d-none");
  }

  rimuoviAutorizzazione(e) {
    e.preventDefault();
    this.operationTarget.value = "autorizzato";
    this.valueTarget.value = "false";
    this.showLoading();
    this.element.requestSubmit();
  }

  dataLavorazione(e) {
    e.preventDefault();
    this.operationTarget.value = "data_lavorazione";
    this.valueTarget.value = this.dataLavorazioneTarget.value;
    this.showLoading();
    this.element.requestSubmit();
  }

  sede(e) {
    e.preventDefault();
    this.operationTarget.value = "work_place_anag_id";
    this.valueTarget.value = this.sedeTarget.value;
    this.showLoading();
    this.element.requestSubmit();
  }

  statoLavorazione(e) {
    e.preventDefault();
    this.operationTarget.value = "stato_lavorazione";
    this.valueTarget.value = this.statoLavorazioneTarget.value;
    this.showLoading();
    this.element.requestSubmit();
  }

  lostQuotationElementReason(e) {
    e.preventDefault();
    this.operationTarget.value = "lost_quotation_element_reason_id";
    this.valueTarget.value = this.lostQuotationElementReasonTarget.value;
    this.showLoading();
    this.element.requestSubmit();
  }

  sconto(e) {
    e.preventDefault();
    this.operationTarget.value = "differenza_cliente";
    this.valueTarget.value = this.scontoTarget.value;
    this.showLoading();
    this.element.requestSubmit();
  }

  anag(e) {
    e.preventDefault();
    this.operationTarget.value = "anag_id";
    this.valueTarget.value = this.anagTarget.value;
    this.showLoading();
    this.element.requestSubmit();
  }

  anag(e) {
    e.preventDefault();
    this.operationTarget.value = "anag_id";
    this.valueTarget.value = this.anagTarget.value;
    this.showLoading();
    this.element.requestSubmit();
  }

  humanResource(e) {
    e.preventDefault();
    this.operationTarget.value = "human_resource_id";
    this.valueTarget.value = this.humanResourceTarget.value;
    this.showLoading();
    this.element.requestSubmit();
  }

  okFornitore(e) {
    e.preventDefault();
    this.operationTarget.value = "ok_fornitore";
    this.valueTarget.value = "true";
    this.showLoading();
    this.element.requestSubmit();
    // this.actionsContainerTarget.classList.add("d-none");
  }

  rimuoviOkFornitore(e) {
    e.preventDefault();
    this.operationTarget.value = "ok_fornitore";
    this.valueTarget.value = "false";
    this.showLoading();
    this.element.requestSubmit();
  }

  fatturato(e) {
    e.preventDefault();
    this.operationTarget.value = "fatturato";
    this.valueTarget.value = "true";
    this.showLoading();
    this.element.requestSubmit();
    // this.actionsContainerTarget.classList.add("d-none");
  }

  nonFatturato(e) {
    e.preventDefault();
    this.operationTarget.value = "fatturato";
    this.valueTarget.value = "false";
    this.showLoading();
    this.element.requestSubmit();
  }

  numeroFattura(e) {
    e.preventDefault();
    this.operationTarget.value = "numero_fattura";
    this.valueTarget.value = this.numeroFatturaTarget.value;
    this.showLoading();
    this.element.requestSubmit();
  }

  dataFattura(e) {
    e.preventDefault();
    this.operationTarget.value = "data_fattura";
    this.valueTarget.value = this.dataFatturaTarget.value;
    this.showLoading();
    this.element.requestSubmit();
  }
}
